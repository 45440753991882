<template>
    <div>
      <b-container class="mt-5 mb-5">
        <b-button :to="'/manage/events/' + this.$route.params.id + '/guests/create'" variant="primary" class="float-right">
          Toevoegen
        </b-button>
        <h1 class="fs-4 font-weight-bold mb-4">Gasten</h1>
        
        <b-card class="mb-3">
            <div v-if="orders.length <= 0" class="text-center text-muted mt-4 mb-4">
                <font-awesome-icon icon="users" class="mb-2" size="2x" />
                <div class="text-dark fs-5">Geen gasten gevonden</div>
                <div>Je hebt nog geen gasten toegevoegd.</div>
            </div>
            <template v-else>
                <div class="table-responsive">
                    <table class="table table-striped">
                    <thead>
                        <tr class="text-muted">
                        <th>Bestelnr.</th>
                        <th>Klant</th>
                        <th>Kosten</th>
                        <th>Status</th>
                        <th>Tickets</th>
                        <th>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(order,key) in orders" :key="key" @click="goToDetail(order)" class="cursor-pointer">
                        <td>{{ order.order_id }}</td>
                        <td>{{ order.firstname }} {{ order.lastname }}</td>
                        <td>{{ order.total_price|toCurrency }}</td>
                        <td>
                            <template v-if="order.canceled_at">
                                <b-badge variant="danger" class="bg-danger">
                                Geannuleerd
                                </b-badge>
                            </template>
                            <template v-else>
                                <template v-if="order.payment_status == 'paid'">
                                <b-badge variant="success" class="bg-success">
                                    Betaald
                                </b-badge>
                                </template>
                                <template v-if="order.payment_status == 'open' || order.payment_status == 'pending' || order.payment_status == 'created'">
                                <b-badge variant="warning" class="bg-warning">
                                    Open
                                </b-badge>
                                </template>
                                <template v-if="order.payment_status == 'expired'">
                                <b-badge variant="danger" class="bg-danger">
                                    Verlopen
                                </b-badge>
                                </template>
                                <template v-if="order.payment_status == 'canceled'">
                                <b-badge variant="danger" class="bg-danger">
                                    Geannuleerd
                                </b-badge>
                                </template>
                            </template>
                        </td>
                        <td>{{ order.tickets_sold }}</td>
                        <td>{{ order.created_at|formatDateTime }}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </template>
        </b-card>
      </b-container>
    </div>
</template>
  
<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'
    
    export default {
        metaInfo: {
            title: 'Gasten',
        },
        components: {},
        data() {
            return {
                orders: []
            }
        },
        computed: {},
        methods: {
            ...mapActions(useShopStore, ['setLoading']),
            fetchGuests: function() {
                this.setLoading(true);
                this.$axios.get('https://api.tixgo.nl/events/' + this.$route.params.id + '/guests')
                    .then( response => {
                        if (response.data) {
                            this.orders = response.data;
                            this.setLoading(false);
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            goToDetail: function(order) {
                this.$router.push('/manage/orders/' + order.id);
            }
        },
        created() {
            this.fetchGuests();
        },
    }
</script>